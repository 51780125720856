<template>
  <div class="err">
    <Empty image="error" description="找不到页面" />
  </div>
</template>

<script>
import {Empty} from "vant"
export default {
  components:{
    Empty
  },
  name: 'page404'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.err{
  width: 100%;
  height: 100vh;
  .van-empty{
    width: 100%;
    height: 100%;
  }
  button{
    width: 200px;
  }
}
</style>
